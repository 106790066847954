










































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Document, IconsType } from "@/types";
import { namespace } from "vuex-class";
import {
  mdiClose,
  mdiDownloadOutline,
  mdiMinus,
  mdiPlus,
  mdiRotateLeft,
  mdiRotateRight
} from "@mdi/js";
const ApplicationModule = namespace("ApplicationModule");
const AppModule = namespace("AppModule");
@Component({
  components: {
    DialogDownload: () => import("@/components/Menu/DialogDownload.vue")
  }
})
export default class DocumentViewer extends Vue {
  @Prop() readonly minHeight!: number;
  @Prop({ default: false }) readonly dialog!: boolean;
  //
  // COMPUTED
  @AppModule.State
  public height!: number;
  @AppModule.Getter
  public contentHeight!: number;
  @ApplicationModule.State
  public documents!: Document[];
  @ApplicationModule.State
  public downloadPercentage!: number;
  get percentage() {
    return this.downloadPercentage?.toFixed(0) || "0";
  }
  get docHeight() {
    if (this.dialog) return this.height - 81;
    else
      return (
        (this.contentHeight < this.minHeight
          ? this.minHeight
          : this.contentHeight) - 89
      );
  }
  get isImage(): boolean {
    return this.selectedDocument.type === "image";
  }
  get isPDF(): boolean {
    return this.selectedDocument.type === "pdf";
  }
  get imageTranslate() {
    switch (Math.abs(this.imageRotation % 360)) {
      case 270:
        return "0%, 0%";
      case 180:
        return "-50%, -50%";
      case 90:
        return "0%, 0%";
      default:
        return "-50%, -50%";
    }
  }
  get imageStyle() {
    const style: any = {
      transform: `rotate(${this.imageRotation}deg) translate(${this.imageTranslate})`,
      width: `${100 * this.imageScale}%`,
      height: `${100 * this.imageScale}%`
    };
    const offset = `${50 * (this.imageScale < 1 ? 1 : this.imageScale)}%`;
    if (Math.abs(this.imageRotation % 360) === 0) {
      style.top = offset;
      style.left = offset;
    }
    return style;
  }
  //
  // DATA
  private preview = "";
  private imageScale = 1;
  private imageRotation = 0;
  private selectedDocument: Document = { id: "", type: "", size: 0, name: "" };
  private loading = false;
  private icons: IconsType = {
    close: mdiClose,
    plus: mdiPlus,
    download: mdiDownloadOutline,
    minus: mdiMinus,
    rotateRight: mdiRotateRight,
    rotateLeft: mdiRotateLeft
  };
  //
  // METHODS
  onPreview() {
    this.loading = true;
    setTimeout(async () => {
      let response = await this.$store.dispatch(
        "ApplicationModule/viewDocument",
        this.selectedDocument.id
      );
      response = response.split(";");
      const contentType = response[0].substr(5);
      const base64Data = response[1].substr(7);
      const file = this.base64toFile(
        base64Data,
        this.selectedDocument.name,
        contentType
      );
      this.preview = URL.createObjectURL(file);
      this.loading = false;
    });
  }
  base64toFile(base64Data: string, name: string, contentType: string) {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new File(byteArrays, name, { type: contentType });
  }
  onScaleMinus() {
    this.imageScale -= 0.1;
  }
  onScalePlus() {
    this.imageScale += 0.1;
  }
  onRotateRight() {
    this.imageRotation += 90;
  }
  onRotateLeft() {
    this.imageRotation -= 90;
  }
  //
  // LIFECYCLE HOOKS
  created() {
    this.selectedDocument = this.documents[0];
    this.onPreview();
  }
}
