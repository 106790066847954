import { render, staticRenderFns } from "./DocumentViewer.vue?vue&type=template&id=cc1a9b8c&scoped=true&"
import script from "./DocumentViewer.vue?vue&type=script&lang=ts&"
export * from "./DocumentViewer.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentViewer.vue?vue&type=style&index=0&id=cc1a9b8c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc1a9b8c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VBtnToggle,VContainer,VIcon,VSelect,VSkeletonLoader})
